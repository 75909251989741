@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600&display=swap");

/* font-family: 'Lora', serif; */
* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}

html,
body {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

/* prueba de navbar responsive */
.logo {
  margin-left: 10%;
}

.NavBar-icons{
display: none;
font-size: 2rem;

}



.NavBar-icons::before {
  padding: 1.875rem;
}

.NavBar-icons:hover {
  color: #f8de15;
}





.navbar {
  position: fixed !important;
  width: 100%;
  height: 6.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #1a4ea0;
  /* position: absolute; */
  z-index: 2;
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1.0625rem;
  font-family: "Montserrat";
  letter-spacing: 0.0313rem;
  top: 0;
}

.navbar img {
  width: 5rem;
}

.nav-menu {
  display: flex;
  margin-right: 1rem;
}

.nav-item {
  text-decoration: none;
  color: white;
  position: relative;
  padding: 1rem;
}

.hamburger {
  display: none;
}

@media screen and (min-width: 940px) {
  .nav-item:hover {
    border-radius: 2px;
    background: #053f9c;
  }

  .nav-item:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 0.1875rem;
    border-radius: 0.125rem;
    bottom: 0;
    left: 0;
    background-color: #f8de15;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  .nav-item:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
}

@media screen and (max-width: 940px) {
  .nav-menu {
    position: fixed;
    left: -100%;
    top: 100px;
    flex-direction: column;
    justify-content: center;
    background-color: #1a4ea0;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: 0.4s;
  }

  .nav-menu.active {
    left: 0;
  }

  .nav-item {
    margin: 1.5rem;
    font-size: 1.8rem;
    justify-content: center;
  }

  .nav-item:hover {
    border-left: 6px solid #f8de15;
  }

  .hamburger {
    display: flex;
    padding-right: 0.625rem;
    cursor: pointer;
  }

.logo {
  margin-left: 4%;
}
  .NavBar-icons{
    display: flex;
    text-decoration: none;
  }

  .FloatIcon {
    margin-bottom: 0;
    margin-left: 3px!important;

  }

  .Arrep {
    margin-left: 0rem!important;
  }

 }

.overlay {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.content {
  position: absolute;

  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10%;
}
.btnHome-1 {
  background: linear-gradient(
    40deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(31, 48, 139, 1) 35%,
    rgba(0, 212, 255, 1) 100%
  );
}
.btnHome-1:hover {
  background: linear-gradient(
    90deg,
    rgba(76, 58, 180, 1) 0%,
    rgba(253, 29, 29, 1) 50%,
    rgba(252, 176, 69, 1) 100%
  );
  transition: 0.5s;
}

.FloatIcon{
  margin-left: 4.2rem;
  position: fixed;
  width: 4rem;
  bottom: 1.3125rem;
  color: #FFF;
  border-radius: 50%;
  font-size: 2.375rem;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(234 241 234);
  height: 4rem;
  background: green;

}

.FloatIcon:hover{
  transform: scale3d(1.06, 1.06, 1.06);
}

.intro {
  width: 80%;
  margin: 4rem auto;
  box-shadow: 0 0.2px #f8de15;
  margin-top: 10rem;
}

.HomeHeader {
  position: absolute;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  top: 0;
}

.header {
  color: #ffffff;
  padding: 1.5625rem;

  
}

.header_title {
  font-size: 3.5rem;
  font-weight: 700;
  margin-top: 12.25rem;
  text-shadow: 0 0 1.5625rem black;
  margin-bottom: 1.5625rem;
  border-bottom: 0.3rem solid #f8de15;

  
}

.header_text {
  font-weight: 400;
  font-size: 2.75rem;
  line-height: 1.5625rem;

  text-shadow: 0 0 1.5625rem black;
  margin-bottom: 2.1875rem;
}

/* categories Cards */

.categories {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5rem;
  justify-content: center;
}

/* images cards */
.thumbnailContainer {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5rem;
  justify-content: center;
}

.thumbnailContainer > div > img {
  width: auto;
  object-fit: cover;
  height: 10rem;
}

.thirdHeader {
  margin-bottom: 6.25rem;
  color: #35464e;
  padding: 1.5625rem;
  margin-left: 10%;
  width: 80%;
}

.thirdHeader_title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-top: 2.25rem;
}

.thirdHeader_text {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 2.1875rem;
  letter-spacing: 0.06em;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}

.categoryImg {
  width: 14rem;
  height: 18.6875rem;
  object-fit: cover;
  object-position: left;
  border-radius: 1.375rem;
  margin: 1.25rem;
  filter: brightness(0.8);
}

.categoryText {
  position: absolute;
  color: #ffff;
  font-size: 1.375rem;
  font-weight: bold;
  text-transform: uppercase;
  /* text-decoration: none; */
  text-align: center;
}
.card-img-overlay {
  border-radius: 1.375rem !important;
  box-shadow: 4px 1px 4px 2px rgb(0 0 0 / 25%);
}

.categoryCard:hover{
  transform: scale3d(1.02, 1.02, 1.02);
}

/* CONTACTO */

.rectangle-b {
  width: 21.875rem;
  height: 32.3125rem;
  background: #1a4ea0;
  z-index: 1;
  margin-top: 8rem;

}

.YellowLine {
  width: 12.5rem;

  margin-top: 3.125rem;
  margin: 2.5rem auto;

  border: 4px solid #f8de15;
}

.contactHeader {
  color: #1a4ea0;
  width: 31.25rem;
  padding: 1.5625rem;
  margin-top: 2rem;
}

.contactYellowBorder {
  width: 9.8125rem;

  border: 4px solid #f8de15;
  margin-left: 1.75rem;
}

.row > * {
  width: 94% !important;
  margin-left: 2rem;
}

.main-footer {
  background: rgba(0, 0, 0, 0.88);
  color: #ffffff;
  font-family: "Montserrat";
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.footer-text {
  font-size: 0.875rem;
  font-weight: 400;
}

.footer-bottom {
  margin-top: 2rem;
  font-size: 0.8125rem;
  margin-left: 10%;
}

.footer-yellowBorder {
  width: 7rem;
  border: 3px solid #f8de15;
}

.footerLi:hover {
  color: #f8de15;
}

.cardsContainer {

  font-family: "Montserrat";
  background: #f5f5f5;
  box-shadow: 4px 1px 4px 2px rgb(0 0 0 / 25%);
  border-radius: 0.9375rem;
  position: relative;
/*   left: 1.875rem;
 */}

.SideBar {
  background-color: #1a4ea0;
  color: #ffffff;
  font-size: 1rem;
  display: flex;
  flex-direction: column-reverse;
  left: 0;
  position: absolute;
  position: fixed;
  /* width: 700px; */
  top: 0;
  justify-content: center;
  padding: 1.25rem;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  width: 3.9375rem;
  z-index: 2;
}

.SideBarText {
  transform: rotate(270deg);
  line-height: 5px;
  letter-spacing: 3px;
  margin-top: 5rem;
  margin-bottom: 7.5rem;
  width: 15.625rem;
  font-size: 1rem;
  text-decoration: none;
}

.SideBarText:hover {
  color: #f8de15 !important;
}

.SideBar-icons {
  font-size: 1.125rem;
}

.SideBar-icons::before {
  padding: 1.875rem;
}

.SideBar-icons:hover {
  color: #f8de15;
}

.SideBar-i {
  margin: 1.25rem;
}

/* Items */

.containerItems {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10rem;
  margin-bottom: 5rem;
  justify-content: center;
}

.detailImg {
  width: 100%;
  object-fit: cover;
  height: 100vh;
  object-position: center;
  filter: contrast(0.7);
}

/* Detail */

.cardDetail {
  position: absolute;
  width: 45rem;
  box-shadow: 4px 1px 4px 2px rgb(0 0 0 / 25%);
  border-radius: 15px;
  margin-top: 6rem;
  margin-bottom: 6rem;
  transform: translateY(-18rem);
  left: 10%;
}

.cardTitle {
  font-size: 2.25rem;
  font-weight: bold;
}

.card-title {
  font-size: 1.5rem !important;
  color: black;
}

.cardBody {
  padding: 2rem 2rem !important;
}

.iconBox {
  position: relative;
  width: 7.3rem;
  height: 7rem;
  background: #d9d9d9;
  border-radius: 4px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  /* padding-top: 0.3125rem; */
  margin-bottom: 1.5625rem;
}

.iconDetail {
  width: 1.25rem;
  height: 1.6669rem;
  margin: 2px;
}

.iconText {
  font-weight: 600;
  font-size: 0.7rem;
  text-align: center;
  margin-top: 6px;

  padding-left: 2px;
  padding-right: 2px;
  margin-bottom: 8px;
}

.btnReserva {
  background-color: #f44336;
  border-radius: 0.625rem;
  color: #ffff;
  font-size: 1.125rem;
  margin: 2rem;
  font-weight: 600;
  width: 200px;
}

.btnReserva:hover {
  background-color: #ff5722 !important;
}

.linkReserva {
  text-decoration: none;
  color: #d9d9d9;
}

.linkReserva:hover {
  color: #fff;
}

/* Btn Arrepentimiento */

.Arrep {
  display:flex;
    flex-direction:column;
    flex-wrap:wrap;
    margin-top:7rem;
    justify-content: center;
    margin-left: 10rem;
   
}

.BtnArrepHeader {
  color: #1a4ea0;
  padding: 1.5625rem;
}

/* Formulario */

.formulario {
  width: 32rem;
  margin-bottom: 9.375rem;
  margin-top: 3.125rem;
  margin-right: 1rem;

  flex-direction: column;
  display: flex;
  justify-content: center;
  align-content: center;

  background: #fff;
  border-radius: 0.3125rem;
  padding: 1.25rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.formulario > div {
  margin-bottom: 1.25rem;
}

.formulario label {
  display: block;
  font-weight: 600;
  font-size: 0.875rem;
  margin-bottom: 0.3125rem;
  color: #1f1f1f;
}

.formulario input,
.formulario textarea {
  font-family: "Open Sans", sans-serif;
  width: 100%;
  border-radius: 0.3125rem;
  border: 2px solid #e2e2e2;
  font-size: 1.125rem;
  padding: 0.625rem;
  margin-bottom: 0.3125rem;
  color: #1f1f1f;
}

.formulario input:focus {
  outline: none;
  border: 2px solid #0085ff;
}

.formulario .error {
  color: #e92b2d;
  font-weight: 600;
  font-size: 0.75rem;
}

.formulario button[type="submit"] {
  display: block;
  background: #0085ff;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  border: none;
  cursor: pointer;
  width: 100%;
  padding: 0.625rem;
  border-radius: 0.3125rem;
  color: #fff;
  font-size: 1rem;
  transition: 0.3s ease all;
}
.btnForm[type="submit"]{
  background: #ffdf00;
  border-radius: "5px";
}


.btnForm[type="submit"]:hover {
  background: #4caf50;
}

.formulario .exito {
  text-align: center;
  font-size: 0.875rem;
  padding-top: 1.25rem;
  color: #02a802;
}

.formulario input[type="radio"] {
  width: auto;
}

/*---------------------MEDIA-------------------------------------------*/
@media only screen and (max-width: 450px) {
  body,
  html {
    font-size: 6px;
  }
}

@media only screen and (max-width: 590px) {
  body,
  html {
    font-size: 8px;
  }
}

@media only screen and (max-width: 650px) {
  .cardDetail {
    max-width: 32rem;
  }

  .cardTitle {
    font-size: 2rem;
  }

  .iconBox {
    margin-right: 2px;
  }

  body,
  html {
    font-size: 10px;
  }
}

@media only screen and (max-width: 750px) {
  .SideBar {
    display: none;
  }

  .NavBar {
    left: 86%;
  }

  body,
  html {
    font-size: 11px;
  }

  .header_title {
    font-size: 2.5rem;
    text-align: initial;
    margin-top: 9.25rem;
  }

  .thirdHeader_title {
    font-size: 2.5rem;
    text-align: initial;
  }

  .yellowBorder {
    top: 10rem;
  }

  .header_text {
    font-size: 1.1rem;
  }
}

@media only screen and (max-width: 820px) {
  body,
  html {
    font-size: 12px;
  }

  .rectangle-b {
    display: none;
  }
}

@media only screen and (max-width: 1068px) {
  .rectangle-b {
    right: 0;
  }
}
