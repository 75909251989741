.dropdown-menu {
  background: #053f9c !important;
  width: 12.5rem;
  position: absolute;
  top: 5rem;
  list-style: none;
  text-align: start;
  font-size: 1.1rem;
  font-weight: 400;
  margin-top: 1rem;
}

.dropdown-menu li {
  background: #053f9c;
  cursor: pointer;

}

.dropdown-menu li:hover {
  background: #5cabff;
}

.dropdown-menu.open {
  display: block;
  text-transform: uppercase;
  text-align: left; 
   top: 3.5rem;
}

.dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #fff;
  padding: 1rem;
  overflow: hidden;
}

@media screen and (max-width: 940px) {
  .fa-caret-down {
    display: none;
  }

  .dropdown-menu {
    width: 12rem;
    left: 27.5rem;
    top: 17.5rem;
  }

  .dropdown-menu.open {
    display: contents;
  }

  .dropdown-menu li {
    background: none;
    margin-top: 1.1rem;
    font-size: 1.3rem;
    text-align: center;
  }
}
